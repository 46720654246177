<template>
    <v-app class="ma-0">
        <v-app-bar justify="center" color="matchpal">
            <v-icon style="margin-right:10px;" size="30" @click="$router.go(-1)">keyboard_return</v-icon>
            <v-toolbar-title>Quadras</v-toolbar-title>
        </v-app-bar>
        <v-container>
            <!-- Tela Inicial -->
            <v-container fluid class="ma-0 pa-0">
                <v-col
                v-if="!quadras.length"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                >
                    <v-card>
                        <v-card-title class="text-no-wrap">
                            <v-row no-gutters style="max-width: 100%">
                                <v-col href="6" cols="10" class="subheading font-weight-bold text-truncate">
                                    <label for="add">Adicionar Quadra</label>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn id="add" rounded icon plain color="primary" @click="openDialog(0)"><v-icon>add</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-data-iterator
                    v-if="quadras.length"
                    :items="quadras"
                    hide-default-footer
                    disable-pagination
                >
                    <!-- <template v-slot:header>
                        <v-toolbar
                            class="ma-0"
                            color="indigo darken-5"
                            dark
                        >
                            <v-toolbar-title>This is a header</v-toolbar-title>
                        </v-toolbar>
                    </template> -->

                    <template v-slot:default="props">
                        <v-row reverse>
                            <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                            >
                                <v-card>
                                    <v-card-title class="text-no-wrap">
                                        <v-row no-gutters style="max-width: 100%">
                                            <v-col href="6" cols="10" class="subheading font-weight-bold text-truncate">
                                                <label for="add">Adicionar Quadra</label>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn id="add" rounded icon plain color="primary" @click="openDialog(0)"><v-icon>add</v-icon></v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                            <v-col
                            v-for="item in props.items"
                            :key="item.nome"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                            >
                                <v-card>
                                    <v-card-title class="text-no-wrap">
                                        <v-row no-gutters style="max-width: 100%">
                                            <v-col cols="10" class="subheading font-weight-bold text-truncate">
                                            {{ item.nome }}
                                            </v-col>
                                            <v-col cols="2">
                                            <v-dialog
                                            v-model="duplicarModel"
                                            scrollable
                                            max-width="300px"
                                            :retain-focus="false"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    style="background: none" icon class="mr-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="novaQuadra.nome = ''; copiarInformacoes(item)"
                                                    >
                                                        <v-icon>content_copy</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title>
                                                        Duplicar quadra
                                                    </v-card-title>
                                                    <v-divider />
                                                    <v-card-text>
                                                        <v-text-field
                                                            v-model="novaQuadra.nome"
                                                            label="Nome da quadra"
                                                        />
                                                    </v-card-text>
                                                    <v-divider />
                                                    <v-card-actions>
                                                        <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            @click="duplicarQuadra()"
                                                        >
                                                            Duplicar
                                                        </v-btn>
                                                        <v-btn
                                                            color="error"
                                                            text
                                                            @click="duplicarModel = false"
                                                        >
                                                            Cancelar
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>

                                    <v-divider />

                                    <v-list dense>

                                        <v-list-item>
                                            <v-list-item-content>Piso:</v-list-item-content>
                                            <v-list-item-content class="align-end">
                                            {{ item.piso }}
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Dimensões:</v-list-item-content>
                                            <v-list-item-content class="align-end">
                                            {{ item.largura }} x {{ item.comprimento }}
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Área coberta:</v-list-item-content>
                                            <v-list-item-content class="align-end">
                                                <span v-if="item.hasCobertura">Sim</span><span v-else>Não</span>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>Esportes:</v-list-item-content>
                                            <v-list-item-content v-if="item.esportes.length" class="align-end">
                                                <div v-for="e in item.esportes" :key="e.tipo" no-data-text="Nenhuma quadra selecionada"> {{ e.tipo }} </div>
                                            </v-list-item-content>
                                            <v-list-item-content v-else class="align-end">
                                                <div>Nenhum esporte</div>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content class="align-end">
                                                <v-btn
                                                color="primary"
                                                elevation="2"
                                                small
                                                @click="editarQuadra(item, 1)"
                                                >
                                                Editar Informações
                                                </v-btn>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content class="align-end">
                                                <v-btn
                                                color="primary"
                                                elevation="2"
                                                small
                                                @click="editarQuadra(item, 2)"
                                                >
                                                Editar Esportes
                                                </v-btn>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content class="align-end">
                                                <v-btn
                                                color="primary"
                                                elevation="2"
                                                small
                                                @click="editarQuadra(item, 3)"
                                                >
                                                Editar Horários e Valores
                                                </v-btn>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content class="align-end">
                                                <v-btn
                                                color="error"
                                                elevation="2"
                                                small
                                                @click="excluirQuadra(item)"
                                                >
                                                Excluir
                                                </v-btn>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-container>
                <!-- Adicionar Quadra -->
            <v-dialog
                v-model="dialogNovaQuadra"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                persistent
            >
                <!-- Card Adicionar Quadra -->
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="dialogNovaQuadra = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="criarQuadra">Nova quadra</v-toolbar-title>
                        <v-toolbar-title v-else>
                            <div v-if="pag < 3">{{ titulo }}</div>
                            <div v-else class="d-sm-flex d-none">{{ titulo }}</div>
                        </v-toolbar-title>
                        <v-spacer />
                        <div v-if="pag == 3">
                            <v-btn style="background: none" dark icon class="mr-2" @click="limparCriaHoras(); addHoras = true">
                                <v-icon>add</v-icon>
                            </v-btn>
                            <v-btn :disabled="!selected.length"
                                style="background: none"
                                dark icon class="mr-2" @click="setFimVigencia(selected)">
                                <v-badge
                                color="orange"
                                :content="selected.length"
                                :value="selected.length"
                                >
                                    <v-icon>delete_sweep</v-icon>
                                </v-badge>
                            </v-btn>

                            <v-dialog
                            v-model="dialogLista"
                            scrollable
                            max-width="300px"
                            :retain-focus="false"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :disabled="!selected.length"
                                    style="background: none" dark icon class="mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="criarValor = ''"
                                    >
                                        <v-badge
                                        color="green"
                                        :content="selected.length"
                                        :value="selected.length"
                                        >
                                            <v-icon>attach_money</v-icon>
                                        </v-badge>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        Alterar preço dos horários
                                    </v-card-title>
                                    <v-divider />
                                    <v-card-text>
                                        <v-text-field
                                            v-model="criarValor"
                                            v-maska="['#,##','##,##','###,##']"
                                            label="Novo valor"
                                            prefix="R$"
                                        />
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-actions>
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="alterarValor(selected, criarValor)"
                                        >
                                            Alterar valor
                                        </v-btn>
                                        <v-btn
                                            color="error"
                                            text
                                            @click="dialogLista = false"
                                        >
                                            Cancelar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                        </div>
                    </v-toolbar>

                    <!-- Adicionar quadra card -->
                    <v-card-text class="pa-0 ma-0">
                            <v-stepper v-model="pag">
                                <v-stepper-header v-if="criarQuadra">
                                    <v-stepper-step :complete="pag > 1" step="1">Cadastro</v-stepper-step>
                                    <v-divider />
                                    <v-stepper-step :complete="pag > 2" step="2">Esportes</v-stepper-step>
                                    <v-divider />
                                    <v-stepper-step step="3">Horários</v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items class="pa-0 ma-0">
                                    <v-stepper-content step="1">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="novaQuadra.nome"
                                                    label="Nome da Quadra/Pista/Campo*"
                                                    required
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="novaQuadra.piso"
                                                    :items="['Areia', 'Concreto', 'Emborrachado', 'Grama Natural', 'Grama Sintética', 'Madeira', 'Saibro', 'Piso Polido']"
                                                    label="Piso*"
                                                    required
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="novaQuadra.hasCobertura"
                                                    :items="[{text: 'Sem cobertura', value: 0},{text: 'Com cobertura', value:1}]"
                                                    item-text="text"
                                                    item-value="value"
                                                    label="Cobertura*"
                                                    required
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="novaQuadra.largura"
                                                    v-maska="'####'"
                                                    label="Largura*"
                                                    suffix="metros"
                                                    required
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="novaQuadra.comprimento"
                                                    v-maska="'####'"
                                                    label="Comprimento*"
                                                    suffix="metros"
                                                    required
                                                />
                                            </v-col>
                                            <small class="pl-4 mb-2">*Obrigatório</small>
                                        </v-row>
                                        <v-btn class="mt-4" color="primary" @click="salvarQuadra">Salvar</v-btn>
                                    </v-stepper-content>

                                    <v-stepper-content step="2">
                                        <v-row>
                                            <v-col>
                                                <v-list dense>
                                                    <v-list-item-title>Esportes</v-list-item-title>
                                                    <v-list-item
                                                        v-for="(esporte) in esportes"
                                                        :key="esporte.id"
                                                    >
                                                        <v-checkbox
                                                            v-model="selectedEsportes"
                                                            :label="esporte.tipo"
                                                            :value="esporte.id"
                                                        />

                                                    </v-list-item>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                        <v-btn
                                            color="primary"
                                            @click="salvaEsportesQuadra"
                                        >Salvar</v-btn>
                                    </v-stepper-content>

                                    <v-stepper-content step="3" class="ma-0 pa-0">
                                        <v-toolbar
                                        color="primary"
                                        dark
                                        flat
                                        >
                                            <v-tabs
                                                v-model="tabs"
                                                dark
                                                centered
                                                center-active
                                                class="pa-0"
                                                show-arrows
                                            >
                                                <v-tab>Dom</v-tab>
                                                <v-tab>Seg</v-tab>
                                                <v-tab>Ter</v-tab>
                                                <v-tab>Qua</v-tab>
                                                <v-tab>Qui</v-tab>
                                                <v-tab>Sex</v-tab>
                                                <v-tab>Sáb</v-tab>
                                            </v-tabs>
                                        </v-toolbar>

                                        <v-tabs-items v-model="tabs">
                                            <v-tab-item
                                                v-for="(item, index) in horarios"
                                                :key="index"
                                            >
                                                <v-list>
                                                    <v-list-item-group
                                                        v-model="selected"
                                                        active-class="primary--text"
                                                        multiple
                                                    >
                                                        <v-list-item
                                                            v-for="(horas) in item.horarios"
                                                            :key="horas.id"
                                                            :value="horas.id"
                                                            class="mx-2 d-flex justify-center"
                                                        >
                                                            {{ horaFormatada(horas.hora_inicio) }} ({{ horas.duracao }} min) R${{ horas.preco }}
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-tab-item>
                                        </v-tabs-items>

                                        <v-btn v-if="criarQuadra" :style="{left: '50%', transform:'translateX(-50%)'}"
                                        color="primary" @click="dialogNovaQuadra = false">Concluir</v-btn>
                                        <v-overlay
                                        :value="addHoras"
                                        >
                                            <v-card
                                                class="pa-5"
                                                color="grey lighten-5"
                                                style="textColor: black"
                                                light
                                            >

                                                <!-- TODO: ver como adicionar cabçalho -->

                                                <div class="mb-4" :style="{textAlign: 'center'}" bold>
                                                    Selecione os dias
                                                </div>
                                                <v-row class="mb-5">
                                                    <v-btn-toggle v-model="criarDias" multiple color="primary">
                                                        <v-btn class="py-4" value="Dom" rounded x-small>Dom</v-btn>
                                                        <v-btn class="py-4" value="Seg" rounded x-small>Seg</v-btn>
                                                        <v-btn class="py-4" value="Ter" rounded x-small>Ter</v-btn>
                                                        <v-btn class="py-4" value="Qua" rounded x-small>Qua</v-btn>
                                                        <v-btn class="py-4" value="Qui" rounded x-small>Qui</v-btn>
                                                        <v-btn class="py-4" value="Sex" rounded x-small>Sex</v-btn>
                                                        <v-btn class="py-4" value="Sáb" rounded x-small>Sáb</v-btn>
                                                    </v-btn-toggle>
                                                </v-row>
                                                <v-text-field
                                                    v-model="criarInicio"
                                                    v-maska="['#:##', '##:##']"
                                                    label="Início do primeiro horário"
                                                />
                                                <v-text-field
                                                    v-model="criarDuracao"
                                                    v-maska="['#', '##', '###']"
                                                    label="Duração de cada partida"
                                                    suffix="Minutos"
                                                />
                                                <v-text-field
                                                    v-model="criarValor"
                                                    v-maska="['#,##', '##,##', '###,##']"
                                                    label="Preço por horário"
                                                    prefix="R$"
                                                />

                                                <v-card-actions>
                                                    <v-row justify="center">

                                                        <v-dialog
                                                        v-model="dialogLista"
                                                        scrollable
                                                        max-width="300px"
                                                        :retain-focus="false"
                                                        persistent
                                                        >
                                                            <template v-slot:activator="{ attrs }">
                                                                <v-btn
                                                                color="success"
                                                                dark
                                                                v-bind="attrs"
                                                                @click="listaHorarios()"
                                                                >
                                                                Listar Horários
                                                                </v-btn>
                                                            </template>
                                                            <v-card>
                                                                <v-card-title class="pa-0">
                                                                    <v-tabs
                                                                        v-model="tabs2"
                                                                        centered
                                                                        center-active
                                                                        class="pa-0"
                                                                        show-arrows
                                                                    >
                                                                        <v-tab v-if="criarDias.includes('Dom')">Dom</v-tab>
                                                                        <v-tab v-if="criarDias.includes('Seg')">Seg</v-tab>
                                                                        <v-tab v-if="criarDias.includes('Ter')">Ter</v-tab>
                                                                        <v-tab v-if="criarDias.includes('Qua')">Qua</v-tab>
                                                                        <v-tab v-if="criarDias.includes('Qui')">Qui</v-tab>
                                                                        <v-tab v-if="criarDias.includes('Sex')">Sex</v-tab>
                                                                        <v-tab v-if="criarDias.includes('Sáb')">Sáb</v-tab>
                                                                    </v-tabs>
                                                                </v-card-title>
                                                                <v-divider />
                                                                <v-card-text style="height: 360px;">
                                                                    <v-tabs-items v-model="tabs2">
                                                                        <v-tab-item
                                                                            v-for="(item, index) in horasDisponiveis"
                                                                            :key="index"
                                                                        >
                                                                            <v-list>
                                                                                <v-list-item-group
                                                                                    v-model="horariosCriacao"
                                                                                    active-class="primary--text"
                                                                                    multiple
                                                                                >
                                                                                    <v-list-item
                                                                                        v-for="(horas) in item"
                                                                                        :key="horas"
                                                                                        :value="horas"
                                                                                        class="mx-2 d-flex justify-center"
                                                                                    >
                                                                                        {{ horas.slice(3) }}
                                                                                    </v-list-item>
                                                                                </v-list-item-group>
                                                                            </v-list>
                                                                        </v-tab-item>
                                                                    </v-tabs-items>
                                                                </v-card-text>
                                                                <v-btn
                                                                fab
                                                                dark
                                                                small
                                                                bottom
                                                                right
                                                                class="mt-n12"
                                                                style="margin-left: 80%"
                                                                @click="(selecionarHorarios())"
                                                                >
                                                                    <v-icon>{{ horariosCriacao.filter(x => !!x.includes(criarDias[tabs2])).length ? "check_box_outline_blank" : "check_box" }}</v-icon>
                                                                </v-btn>
                                                                <v-divider />
                                                                <v-card-actions>
                                                                    <v-btn
                                                                        color="blue darken-1"
                                                                        text
                                                                        @click="salvarHorarios()"
                                                                    >
                                                                        Adicionar
                                                                    </v-btn>
                                                                    <v-btn
                                                                        color="blue darken-1"
                                                                        text
                                                                        @click="dialogLista = false, addHoras = false"
                                                                    >
                                                                        Cancelar
                                                                    </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                        <v-spacer />
                                                        <v-btn color="error" @click="addHoras = !addHoras">
                                                            Sair
                                                        </v-btn>
                                                    </v-row>
                                                </v-card-actions>
                                            </v-card>
                                        </v-overlay>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
        <Snackbar :snackbar-flag="snackbar.activeFlag" :permanent="snackbar.permanentFlag"
            :error="snackbar.errorFlag" :color="snackbar.color" :hide-close-buttom="snackbar.isConfirmMode">
            <template v-slot:message>{{ snackbar.text }}</template>
            <template v-slot:actions>
                <v-btn v-if="snackbar.isConfirmMode" color="error"
                    @click.prevent="snackbar.confirm = false; snackbar.activeFlag = 0">Não</v-btn>
                <v-btn v-if="snackbar.isConfirmMode" color="matchpal"
                    @click.prevent="snackbar.confirm = true; snackbar.activeFlag = 0">Sim</v-btn>
            </template>
        </Snackbar>
        <Load :load="load" />
    </v-app>
</template>

<script>
import moment from 'moment'
import Utils from "@components/Utils/Utils.vue"
import { maska } from 'maska'


export default {
    name: 'Quadras',
    components: {
        Snackbar: () => import('@components/Snackbar.vue'),
        Load: () => import('@components/Load.vue')
    },
    directives: { maska },
    mixins: [ Utils ],
    data: () => ({
        items: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        dialogNovaQuadra: false,
        dialog: false,
        dialogLista: false,
        load: false,
        duplicarModel: false,
        duplicarFlag: false,
        excluir: false,
        quadras: [],
        horasDisponiveis: 0,
        criarQuadra: false,
        novaQuadra: {
            id: 0,
            nome: '',
            esportes: '',
            piso: '',
            hasCobertura: undefined,
            comprimento: undefined,
            largura: undefined,
            estabelecimento_id: '',
        },
        pag: 1,
        esportes: [],
        addHoras: false,
        listarHoras: false,
        criarDias: [],
        criarInicio: '',
        criarDuracao: '',
        criarValor: '',
        horariosCriacao: [],
        horariosDuplicar: [],
        horarios: [
            {horarios: ''},
            {horarios: ''},
            {horarios: ''},
            {horarios: ''},
            {horarios: ''},
            {horarios: ''},
            {horarios: ''},
        ],
        tabs: null,
        tabs2: null,
        selected: [],
        selectedEsportes: [],
        rangeEsportes: [],
        titulo: '',
        editarValor: 0,
    }),
    mounted() {
        this.fetchEstabelecimento()
    },
    methods: {
        selecionarHorarios(){
            if(this.horariosCriacao.filter(x => !!x.includes(this.criarDias[this.tabs2])).length)
                this.horariosCriacao = this.horariosCriacao.filter(x => !x.includes(this.criarDias[this.tabs2]))
            else
                for (let i = 0; i < this.horasDisponiveis.length; i++)
                    this.horariosCriacao = this.horariosCriacao.concat(this.horasDisponiveis[i].filter(x => !!x.includes(this.criarDias[this.tabs2])))
        },
        fetchEstabelecimento: function (date) {
            this.$http.post('/apiweb/getEstabelecimento').then(response => {
                if (response.data.success === true)
                    this.novaQuadra.estabelecimento_id = response.data.info.id
                this.getEsportes()
            })
        },
        ignoraFim(i, fim, dia){
            let temp
            while (true){
                if (!this.horarios[dia].horarios.length || i >= this.horarios[dia].horarios.length) return i
                else{
                    temp = moment(this.horarios[dia].horarios[i].hora_inicio, 'HH:mm').add(this.horarios[dia].horarios[i].duracao, 'minutes')
                    if (fim.isSameOrAfter(temp)) i += 1
                    else return i
                }
            }
        },
        listaHorarios(){
            if (parseInt(this.criarInicio.slice(0,2)) > 23) return this.notify('Horário Inválido!', true, false)
            if (!this.criarDias.length || !this.criarInicio || !this.criarDuracao || !this.criarValor) {
                return this.notify('Preencha todos os campos!', true, false)
            }
            let ini = moment(this.criarInicio, 'HH:mm')
            let fim = moment(this.criarInicio, 'HH:mm')
            let i = []
            this.horariosCriacao = []
            let quant = [ [], [], [], [], [], [], [] ]
            let temp
            let horaIni = [ 0, 0, 0, 0, 0, 0, 0]
            while (true){
                if (this.criarDias.includes('Dom') && !i.includes('dom')) {
                    if (!this.horarios[0].horarios.length || horaIni[0] == this.horarios[0].horarios.length) i.push('dom')
                    else{
                        temp = moment(this.horarios[0].horarios[horaIni[0]].hora_inicio, 'HH:mm').add(this.horarios[0].horarios[horaIni[0]].duracao, 'minutes')
                        if (ini.isSameOrAfter(temp)) horaIni[0] += 1
                        else i.push('dom')
                    }
                }
                if (this.criarDias.includes('Seg') && !i.includes('seg')) {
                    if (!this.horarios[1].horarios.length || horaIni[1] == this.horarios[1].horarios.length) i.push('seg')
                    else{
                        temp = moment(this.horarios[1].horarios[horaIni[1]].hora_inicio, 'HH:mm').add(this.horarios[1].horarios[horaIni[1]].duracao, 'minutes')
                        if (ini.isSameOrAfter(temp)) horaIni[1] += 1
                        else i.push('seg')
                    }
                }
                if (this.criarDias.includes('Ter') && !i.includes('ter')) {
                    if (!this.horarios[2].horarios.length || horaIni[2] == this.horarios[2].horarios.length) i.push('ter')
                    else{
                        temp = moment(this.horarios[2].horarios[horaIni[2]].hora_inicio, 'HH:mm').add(this.horarios[2].horarios[horaIni[2]].duracao, 'minutes')
                        if (ini.isSameOrAfter(temp)) horaIni[2] += 1
                        else i.push('ter')
                    }
                }
                if (this.criarDias.includes('Qua') && !i.includes('qua')) {
                    if (!this.horarios[3].horarios.length || horaIni[3] == this.horarios[3].horarios.length) i.push('qua')
                    else{
                        temp = moment(this.horarios[3].horarios[horaIni[3]].hora_inicio, 'HH:mm').add(this.horarios[3].horarios[horaIni[3]].duracao, 'minutes')
                        if (ini.isSameOrAfter(temp)) horaIni[3] += 1
                        else i.push('qua')
                    }
                }
                if (this.criarDias.includes('Qui') && !i.includes('qui')) {
                    if (!this.horarios[4].horarios.length || horaIni[4] == this.horarios[4].horarios.length) i.push('qui')
                    else{
                        temp = moment(this.horarios[4].horarios[horaIni[4]].hora_inicio, 'HH:mm').add(this.horarios[4].horarios[horaIni[4]].duracao, 'minutes')
                        if (ini.isSameOrAfter(temp)) horaIni[4] += 1
                        else i.push('qui')
                    }
                }
                if (this.criarDias.includes('Sex') && !i.includes('sex')) {
                    if (!this.horarios[5].horarios.length || horaIni[5] == this.horarios[5].horarios.length) i.push('sex')
                    else{
                        temp = moment(this.horarios[5].horarios[horaIni[5]].hora_inicio, 'HH:mm').add(this.horarios[5].horarios[horaIni[5]].duracao, 'minutes')
                        if (ini.isSameOrAfter(temp)) horaIni[5] += 1
                        else i.push('sex')
                    }
                }
                if (this.criarDias.includes('Sáb') && !i.includes('sab')) {
                    if (!this.horarios[6].horarios.length || horaIni[6] == this.horarios[6].horarios.length) i.push('sab')
                    else{
                        temp = moment(this.horarios[6].horarios[horaIni[6]].hora_inicio, 'HH:mm').add(this.horarios[6].horarios[horaIni[6]].duracao, 'minutes')
                        if (ini.isSameOrAfter(temp)) horaIni[6] += 1
                        else i.push('sab')
                    }
                }
                if (i.length == this.criarDias.length) break
            }
            this.horariosCriacao = []
            fim.add(this.criarDuracao, 'minutes')
            while(true){
                if (this.criarDias.includes('Dom'))
                    if(this.horarios[0].horarios.length > 0 && !(horaIni[0] == this.horarios[0].horarios.length)){
                        if (fim.isAfter(moment(this.horarios[0].horarios[horaIni[0]].hora_inicio, 'HH:mm')))
                            horaIni[0] = this.ignoraFim(horaIni[0], fim, 0)
                        else quant[0].push('Dom' + ini.format('HH:mm:ss'))
                    }else quant[0].push('Dom' + ini.format('HH:mm:ss'))
                if (this.criarDias.includes('Seg'))
                    if(this.horarios[1].horarios.length > 0 && !(horaIni[1] == this.horarios[1].horarios.length)){
                        if (fim.isAfter(moment(this.horarios[1].horarios[horaIni[1]].hora_inicio, 'HH:mm')))
                            horaIni[1] = this.ignoraFim(horaIni[1], fim, 1)
                        else quant[1].push('Seg' + ini.format('HH:mm:ss'))
                    }else quant[1].push('Seg' + ini.format('HH:mm:ss'))
                if (this.criarDias.includes('Ter'))
                    if(this.horarios[2].horarios.length > 0 && !(horaIni[2] == this.horarios[2].horarios.length)){
                        if (fim.isAfter(moment(this.horarios[2].horarios[horaIni[2]].hora_inicio, 'HH:mm')))
                            horaIni[2] = this.ignoraFim(horaIni[2], fim, 2)
                        else quant[2].push('Ter' + ini.format('HH:mm:ss'))
                    }else quant[2].push('Ter' + ini.format('HH:mm:ss'))
                if (this.criarDias.includes('Qua'))
                    if(this.horarios[3].horarios.length > 0 && !(horaIni[3] == this.horarios[3].horarios.length)){
                        if (fim.isAfter(moment(this.horarios[3].horarios[horaIni[3]].hora_inicio, 'HH:mm')))
                            horaIni[3] = this.ignoraFim(horaIni[3], fim, 3)
                        else quant[3].push('Qua' + ini.format('HH:mm:ss'))
                    }else quant[3].push('Qua' + ini.format('HH:mm:ss'))
                if (this.criarDias.includes('Qui'))
                    if(this.horarios[4].horarios.length > 0 && !(horaIni[4] == this.horarios[4].horarios.length)){
                        if (fim.isAfter(moment(this.horarios[4].horarios[horaIni[4]].hora_inicio, 'HH:mm')))
                            horaIni[4] = this.ignoraFim(horaIni[4], fim, 4)
                        else quant[4].push('Qui' + ini.format('HH:mm:ss'))
                    }else quant[4].push('Qui' + ini.format('HH:mm:ss'))
                if (this.criarDias.includes('Sex'))
                    if(this.horarios[5].horarios.length > 0 && !(horaIni[5] == this.horarios[5].horarios.length)){
                        if (fim.isAfter(moment(this.horarios[5].horarios[horaIni[5]].hora_inicio, 'HH:mm')))
                            horaIni[5] = this.ignoraFim(horaIni[5], fim, 5)
                        else quant[5].push('Sex' + ini.format('HH:mm:ss'))
                    }else quant[5].push('Sex' + ini.format('HH:mm:ss'))
                if (this.criarDias.includes('Sáb'))
                    if(this.horarios[6].horarios.length > 0 && !(horaIni[6] == this.horarios[6].horarios.length)){
                        if (fim.isAfter(moment(this.horarios[6].horarios[horaIni[6]].hora_inicio, 'HH:mm')))
                            horaIni[6] = this.ignoraFim(horaIni[6], fim, 6)
                        else quant[6].push('Sab' + ini.format('HH:mm:ss'))
                    }else quant[6].push('Sab' + ini.format('HH:mm:ss'))
                ini.add(this.criarDuracao, 'minutes')
                fim.add(this.criarDuracao, 'minutes')
                if (ini.isAfter(moment('23:59', 'HH:mm'))) break
            }
            this.dialogLista = true
            if (!quant[1].length && this.criarDias.includes('Seg')) quant[1].push("000Nenhum Horário disponível")
            if (!quant[0].length && this.criarDias.includes('Dom')) quant[0].push("000Nenhum Horário disponível")
            if (!quant[2].length && this.criarDias.includes('Ter')) quant[2].push("000Nenhum Horário disponível")
            if (!quant[3].length && this.criarDias.includes('Qua')) quant[3].push("000Nenhum Horário disponível")
            if (!quant[4].length && this.criarDias.includes('Qui')) quant[4].push("000Nenhum Horário disponível")
            if (!quant[5].length && this.criarDias.includes('Sex')) quant[5].push("000Nenhum Horário disponível")
            if (!quant[6].length && this.criarDias.includes('Sáb')) quant[6].push("000Nenhum Horário disponível")
            quant = quant.filter(x => x.length != 0)
            this.horasDisponiveis = quant
            quant = quant.filter(x => !x.includes("000Nenhum Horário disponível"))
            for (let i = 0; i < quant.length; i++)
                this.horariosCriacao = this.horariosCriacao.concat(quant[i])
        },
        getHorarios(quadra){
            this.load = true
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'post',
                    url: '/apiweb/getHorarios',
                    data: {
                        quadra_id: quadra.id
                    }
                }).then(response => {
                    if (response.data.success) {
                        this.horarios = response.data.horarios
                        resolve({success: true})
                    } else reject({success: false})
                    this.load = false
                }).catch(
                    this.load = false,
                )
            })
        },
        horaFormatada(h){
            return moment(h, 'HH:mm:ss').format('HH:mm')
        },
        setFimVigencia(horarios){
            this.confirm(`Você tem certeza que deseja excluir ${this.selected.length} horário(s)?`).then((confirm) => {
                if (!confirm) return this.dialog = false
                this.load = true
                this.$http({
                    method: 'post',
                    url: '/horarios/setFimVigencia',
                    data: {
                        quadra_id: this.novaQuadra.id,
                        id: horarios,
                        versao_horario: 0
                    }
                }).then(response => {
                    this.load = false
                    if (response.data.mensagem === 'ok') {
                        this.getHorarios(this.novaQuadra)
                    }
                }).catch(error => {
                    this.load = false
                    this.notify('Ocorreu um erro, Tente novamente!', true, false)
                })
            })
        },
        alterarValor(horarios, valor){
            this.load = true
            this.$http({
                method: 'post',
                url: '/horarios/alteraValores',
                data: {
                    quadra_id: this.novaQuadra.id,
                    id: horarios,
                    novoValor: valor.replace(',','.'),
                    versao_horario: 0
                }
            }).then(response => {
                this.load = false
                this.dialogLista = 0
                if (response.data.mensagem === 'ok') {
                    this.getHorarios(this.novaQuadra)
                }
            }).catch(error => {
                this.load = false
                this.dialogLista = 0
                this.notify('Ocorreu um erro, Tente novamente!', true, false)
            })
        },
        editarQuadra(quadra, pag){
            this.selected = []
            this.novaQuadra.id = quadra.id
            this.novaQuadra.nome = quadra.nome
            this.novaQuadra.esportes = quadra.esportes
            this.novaQuadra.piso = quadra.piso
            this.novaQuadra.hasCobertura = quadra.hasCobertura
            this.novaQuadra.comprimento = quadra.comprimento
            this.novaQuadra.largura = quadra.largura
            this.pag = pag
            this.criarQuadra = false
            this.dialogNovaQuadra = true
            if (pag == 1) {
                this.titulo = 'Editar Informações'
            } else if(pag == 2) {
                this.selectedEsportes = []
                for (let i = 0; i < quadra.esportes.length; i++)
                    this.selectedEsportes.push(quadra.esportes[i].id)
                this.titulo = 'Editar Esportes'
            } else {
                this.getHorarios(quadra)
                this.titulo = 'Editar Horários e Valores'
            }
        },
        getQuadras() {
            this.load = true
            this.$http.post('/apiweb/getAllQuadras').then(response => {
                if (response.statusText === "OK")
                    this.quadras = response.data.quadras
                this.load = false
            })
        },
        excluirQuadra(quadra) {
            this.excluir = true
            this.confirm(
                `Você tem certeza que deseja excluir ${quadra.nome}?`
            ).then(confirm => {
                this.excluir = false
                if (!confirm) return (this.dialog = false)
                this.$http({
                    method: 'post',
                    url: '/apiweb/removeQuadra',
                    data: {
                        quadra_id: quadra.id
                    }
                }).then(response => {
                    if (response.statusText === 'OK') this.getQuadras()
                })
            })
        },
        openDialog(pag) {

            if (pag === 0) {
                this.pag = 1
                this.criarQuadra = true
                this.inicializaNovaQuadra()
            }
            this.dialogNovaQuadra = true
        },
        getEsportes() {
            if(!this.criarQuadra) this.load = true
            this.$http.post('esporte').then(response => {
                this.esportes = response.data
                this.esportes.forEach(esporte => {
                    this.rangeEsportes.push(this.getRangeByEsporte(esporte.tipo))
                })
                this.load = false
                this.getQuadras()
            })
        },
        salvarQuadra() {
            return new Promise((resolve, reject) => {
                if (this.verificaCampos()) {
                    this.load = true
                    this.$http({
                        method: 'post',
                        url: '/quadras',
                        data: {
                            quadra: JSON.stringify(this.novaQuadra),
                            versao_quadra: 0,
                        }
                    })
                        .then(response => {
                            this.load = false
                            if (response.data.mensagem === 'ok') {
                                this.novaQuadra.id = response.data.id
                                if (this.criarQuadra) this.pag = 2
                                else this.dialogNovaQuadra = false
                                // this.quadras = response.data.quadras //aqui o retorno response é diferente do responde getAllQuadras
                                if (!this.duplicarFlag) this.getQuadras()
                                resolve({success: true})
                            } else if (response.data.mensagem ==='nome de quadra existente') {
                                this.notify('Já existe uma quadra com esse nome.', true)
                                reject({success: false})
                            }
                        }).catch(error => {
                            this.load = false
                        })
                } else {
                    this.notify('Preencha todos os campos.', true)
                }
            })
        },
        verificaCampos() {
            return (
                this.novaQuadra.nome &&
                this.novaQuadra.piso &&
                this.novaQuadra.hasCobertura != undefined &&
                this.novaQuadra.comprimento &&
                this.novaQuadra.largura
            )
        },
        inicializaNovaQuadra() {
            this.criarQuadra = true
            this.horarios = [
                {horarios: ''},
                {horarios: ''},
                {horarios: ''},
                {horarios: ''},
                {horarios: ''},
                {horarios: ''},
                {horarios: ''},
            ],
            this.selectedEsportes = []
            this.novaQuadra.id = null
            this.novaQuadra.nome = ''
            this.novaQuadra.piso = ''
            this.novaQuadra.hasCobertura = undefined
            this.novaQuadra.comprimento = undefined
            this.novaQuadra.largura = undefined
        },
        getRangeByEsporte(tipo, min) {
            switch (tipo) {
            case 'Basquete':
                return [8, 25]
            case 'Beach Tennis':
                return [2, 9]
            case 'Boliche':
                return [2, 15]
            case 'Futebol de areia':
                return [8, 25]
            case 'Futebol de campo':
                return [20, 51]
            case 'Futebol de salão':
                return [8, 25]
            case 'Futebol society':
                return [8, 25]
            case 'Futevôlei':
                return [4, 9]
            case 'Handebol':
                return [8, 25]
            case 'Padel':
                return [2, 9]
            case 'Paintball':
                return [4, 37]
            case 'Tênis':
                return [2, 9]
            case 'Vôlei':
                return [10, 25]
            case 'Vôlei de praia':
                return [4, 9]
            default:
                return [10, 41]
            }
        },
        salvaEsportesQuadra() {
            return new Promise((resolve) => {
                this.load = true
                let esportesQuadra = []
                for (let i = 0; i < this.selectedEsportes.length; i++) {
                    let esporte_id = this.selectedEsportes[i]
                    this.esportes.forEach(esporte => {
                        if (esporte.id === esporte_id) {
                            esportesQuadra.push(JSON.stringify({
                                esporte_id,
                                min_jogadores: this.rangeEsportes[i][0],
                                max_jogadores: this.rangeEsportes[i][1]
                            }))
                        }
                    })
                }
                this.$http({
                    method: 'post',
                    url: '/quadras/salvaEsportesQuadra',
                    data: {
                        quadra_id: this.novaQuadra.id,
                        esportesQuadra: esportesQuadra,
                        versao_esporteQuadra: 0
                    }
                }).then(response => {
                    if(!this.duplicarFlag) this.getQuadras()
                    this.load = false
                    if (this.criarQuadra) this.pag = 3
                    else this.dialogNovaQuadra = false
                    resolve({success: true})
                }).catch(error => {
                    this.load = false
                })
            })
        },
        limparCriaHoras(){
            this.criarDias = []
            this.criarInicio = ''
            this.criarValor = ''
            this.criarDuracao = ''
            this.horariosCriacao = []
        },
        salvarHorarios(){
            return new Promise((resolve) => {
                this.load = true
                let horarios = []
                if(!this.duplicarFlag){
                    this.horariosCriacao.filter(x => !x.includes("000Nenhum Horário disponível"))
                    for (let i = 0; i < this.horariosCriacao.length; i++){
                        horarios.push(JSON.stringify({
                            dia_semana: this.horariosCriacao[i].slice(0,3),
                            hora_inicio: this.horariosCriacao[i].slice(3),
                            duracao: this.criarDuracao,
                            preco: this.criarValor.replace(',','.'),
                            quadra_id: this.novaQuadra.id,
                            estabelecimento_id: this.novaQuadra.estabelecimento_id,
                        }))
                    }
                } else horarios = this.horariosDuplicar
                this.$http({
                    method: 'post',
                    url: '/apiweb/salvaHorarios',
                    data: {
                        horarios
                    }
                }).then(response => {
                    this.load = false
                    this.addHoras = false
                    this.dialogLista = false
                    if (response.data.mensagem === 'ok') {
                        if(!this.duplicarFlag) this.getHorarios(this.novaQuadra)
                        resolve({success: true})
                    }
                }).catch(error => {
                    this.load = false
                    this.addHoras = false
                    this.dialogLista = false
                    this.notify('Ocorreu um erro, Tente novamente!', true)
                })
            })
        },
        async copiarInformacoes(q){
            this.novaQuadra.id = null
            this.novaQuadra.piso = q.piso
            this.novaQuadra.hasCobertura = q.hasCobertura
            this.novaQuadra.comprimento = q.comprimento
            this.novaQuadra.largura = q.largura
            this.selectedEsportes = []
            q.esportes.forEach(esporte => this.selectedEsportes.push(esporte.id))
            await this.getHorarios(q)
        },
        async duplicarQuadra(){
            this.duplicarFlag = true
            try {
                await this.salvarQuadra()
                await this.salvaEsportesQuadra()

                this.horariosDuplicar = []

                this.horarios.forEach(horario => {
                    for(let i = 0; i < horario.horarios.length; i++){
                        this.horariosDuplicar.push(JSON.stringify({
                            dia_semana: horario.horarios[i].dia_semana,
                            hora_inicio: horario.horarios[i].hora_inicio,
                            duracao: horario.horarios[i].duracao,
                            preco: horario.horarios[i].preco,
                            quadra_id: this.novaQuadra.id,
                            estabelecimento_id: this.novaQuadra.estabelecimento_id
                        }))
                    }
                })
                await this.salvarHorarios()
                this.getQuadras()
                this.duplicarFlag = false
                this.duplicarModel = false
            } catch (error) {
                this.duplicarFlag = false
                this.duplicarModel = false
                this.getQuadras()
                return this.notify('Ocorreu um erro na requisição, tente novamente!', true, false)
            }
        }
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'

    .v-tab
        min-width: 50px
        padding: 0px

</style>